import { Form, Stack } from "react-bootstrap";

import { Link, useNavigate } from "react-router-dom";


import { useEffect } from "react";

import { useLocation } from "react-router-dom";

const PersonalInformation = ({
  handleFormChange,
  form,
  handleEmail,
  handleFormPageChange,
  userInformation,
  handleUserInfoChange,
  // handleFormSubmit,
  handleBackButton,
  setCurrentPage,
}) => {

  // function useQuery() {
  //   return new URLSearchParams(useLocation().search)
  // }

  // let query = useQuery();
  // let source = query.get('tracking_source');

  // useEffect(() => {
  //   console.log(source)
  //   if (source === 'facebook') {
  //     ReactPixel.init('1689471784879141');
  //     ReactPixel.pageView();
  //     // Or use ReactPixel.track('Lead') for specific conversion events
  //   }
  // }, [source]);



  const navigate = useNavigate();
  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (
      userInformation.firstName === "" ||
      userInformation.lastName === "" ||
      userInformation.email === "" ||
      userInformation.phoneNumber === ""
    ) {
      return;
    } else {
      handleEmail();
      setCurrentPage("");
      navigate("/cashoffer");
    }
  };
  return (
    <div>
      <Form
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          alignItems: "center",
        }}
        className="text-success"
      >
        <Form.Label>Personal information *</Form.Label>

        <Stack className="align-items-center justify-content-center gap-4">
          <Form.Control
            name="firstName"
            onChange={handleUserInfoChange}
            value={userInformation.firstName}
            className="form-control-lg"
            style={{ maxWidth: "400px", width: "100%" }}
            placeholder="First name"
          ></Form.Control>

          <Form.Control
            name="lastName"
            onChange={handleUserInfoChange}
            value={userInformation.lastName}
            className="form-control-lg"
            style={{ maxWidth: "400px", width: "100%" }}
            placeholder="Last name"
          ></Form.Control>

          <Form.Control
            name="phoneNumber"
            onChange={handleUserInfoChange}
            value={userInformation.phoneNumber}
            className="form-control-lg"
            style={{ maxWidth: "400px", width: "100%" }}
            placeholder="Your best phone number"
          ></Form.Control>

          <Form.Control
            name="email"
            onChange={handleUserInfoChange}
            value={userInformation.email}
            className="form-control-lg"
            style={{ maxWidth: "400px", width: "100%" }}
            placeholder="Email"
          ></Form.Control>
        </Stack>

        <button
          style={{ width: "10rem" }}
          class="btn btn-success btn-lg"
          onClick={handleFormSubmit}
        >
          Submit
          {/* <Link to="/cashoffer">Submit</Link> */}
        </button>
        <button
          onClick={() => handleBackButton("whenFinalize")}
          style={{ width: "10rem", marginBottom: "1rem" }}
          class="btn btn-dark btn-lg"
        >
          Back
        </button>
      </Form>
    </div>
  );
};

export default PersonalInformation;
