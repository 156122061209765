// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/cashInHand.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `header {
  /* background-image: url(../images/rsz_couple_at_computer.jpg); */
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-position: center;
}

.hero-content {
    background-color: rgb(94, 124, 150);
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Style.css"],"names":[],"mappings":"AAAA;EACE,iEAAiE;EACjE,yDAA+C;EAC/C,sBAAsB;EACtB,2BAA2B;AAC7B;;AAEA;IACI,mCAAmC;AACvC","sourcesContent":["header {\n  /* background-image: url(../images/rsz_couple_at_computer.jpg); */\n  background-image: url(../images/cashInHand.jpg);\n  background-size: cover;\n  background-position: center;\n}\n\n.hero-content {\n    background-color: rgb(94, 124, 150);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
