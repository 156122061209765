import { Container, Stack, Form, Button } from 'react-bootstrap';

import { usePlacesWidget } from "react-google-autocomplete";

import logo from '../images/CFH_LogoV2.png'

const GetOfferPage = ({ handleSubmitAddress, setSelectedAddress }) => {
  const { ref: bootstrapRef3 } = usePlacesWidget({
    apiKey: "YOUR_GOOGLE_API_KEY",
    options: {
      componentRestrictions: { country: "us" },
      types: ["address"],
    },
    onPlaceSelected: (place) => setSelectedAddress(place.formatted_address),
  });

  return (
    <Container>
       
      <Stack className="vh-100 d-flex justify-content-center align-items-center">
        <div>
          <p className='fw-bold display-6 text-center'>Enter your information to get started</p>
        </div>
  
        <div>
          <p className='text-center'>
            Secure & Confidential. We will not give you an offer if your house
            is already listed with a R.E. Agent.
          </p>
        </div>
  
        <Container style={{display: 'flex', gap: '1rem', justifyContent: 'center', flexDirection: 'column', width: '350px'}}>
          <Form.Control placeholder="Enter address..." className='form-control-md' ref={bootstrapRef3} />
          <Button  onClick={handleSubmitAddress} className='bg-success border-0 fw-bold'>Get your offer!</Button>
        </Container>
      </Stack>
    </Container>
  );
};

export default GetOfferPage;

