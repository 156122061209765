// import "bootstrap/dist/css/bootstrap.min.css";

import { Container, Row, Col } from "react-bootstrap";

import HouseCondition from "./HouseCondition";

import PropertyOwnership from "./PropertyOwnership";

import ListedWithAgent from "./ListedWithAgent";

import TypeOfHouse from "./TypeOfHouse";

import HowLongOwned from "./HowLongOwned";

import WhySell from "./whySell";

import RepairsTouchups from "./Repairs&Touchups";

import LivingOnProp from "./LivingOnProp";

import WhenFinalize from "./WhenFinalize";

import PersonalInformation from "./PersonInformation";

import ThankYouPage from "./ThankYouPage";

import { ProgressBar } from "react-bootstrap";

import logo from "../../images/CFH_LogoV2.png";

const FormSection = ({
  handleFormChange,
  form,
  repairs,
  currentFormPage,
  setCurrentFormPage,
  handleFormPageChange,
  userInformation,
  handleUserInfoChange,
  handleFormSubmit,
  handleRepairsChange,
  progessBar,
  handleNextButton,
  handleBackButton,
  setCurrentPage,
  handleEmail
}) => {
  return (
    <div>
      <Container className="my-5">
        <Row>
          <Col>
            <img src={logo} style={{ width: "200px" }} alt="" />
          </Col>
          <Col>
            <p>
              Over 2,500 families trusted Cash4Homes offers to begin their home
              selling process
            </p>
          </Col>
        </Row>
      </Container>

      <Container className="my-5">
        <h3 class="text-center">Enter your information to get started</h3>

        <ProgressBar variant="success" now={progessBar} />
      </Container>

      {/*
      House Condition 
      Property Ownership 
      Type Of House 
      How Long Owned 
      Why You Want To Sell 
      Repairs and touchups 
      Anyone living on the property 
      When do you want to finalize 

       */}

      <Container>
        {currentFormPage === "houseCondition" && (
          <HouseCondition
            handleFormChange={handleFormChange}
            form={form}
            handleFormPageChange={handleFormPageChange}
            handleNextButton={handleNextButton}
          />
        )}

        {currentFormPage === "propertyOwnership" && (
          <PropertyOwnership
            handleFormChange={handleFormChange}
            form={form}
            handleFormPageChange={handleFormPageChange}
            handleNextButton={handleNextButton}
            handleBackButton={handleBackButton}
          />
        )}

        {currentFormPage === "listedWithAgent" && (
          <ListedWithAgent
            handleFormChange={handleFormChange}
            form={form}
            handleFormPageChange={handleFormPageChange}
            handleNextButton={handleNextButton}
            handleBackButton={handleBackButton}
          />
        )}

        {currentFormPage === "typeOfHouse" && (
          <TypeOfHouse
            handleFormChange={handleFormChange}
            form={form}
            handleFormPageChange={handleFormPageChange}
            handleNextButton={handleNextButton}
            handleBackButton={handleBackButton}
          />
        )}

        {currentFormPage === "howLongOwned" && (
          <HowLongOwned
            handleFormChange={handleFormChange}
            form={form}
            handleFormPageChange={handleFormPageChange}
            handleNextButton={handleNextButton}
            handleBackButton={handleBackButton}
          />
        )}

        {currentFormPage === "whySell" && (
          <WhySell
            handleFormChange={handleFormChange}
            form={form}
            handleFormPageChange={handleFormPageChange}
            handleNextButton={handleNextButton}
            handleBackButton={handleBackButton}
          />
        )}

        {currentFormPage === "repairs" && (
          <RepairsTouchups
            handleFormChange={handleFormChange}
            form={form}
            repairs={repairs}
            handleFormPageChange={handleFormPageChange}
            handleRepairsChange={handleRepairsChange}
            handleNextButton={handleNextButton}
            handleBackButton={handleBackButton}
          />
        )}

        {currentFormPage === "livingOnProp" && (
          <LivingOnProp
            handleFormChange={handleFormChange}
            form={form}
            handleFormPageChange={handleFormPageChange}
            handleRepairsChange={handleRepairsChange}
            handleNextButton={handleNextButton}
            handleBackButton={handleBackButton}
          />
        )}

        {currentFormPage === "whenFinalize" && (
          <WhenFinalize
            handleFormChange={handleFormChange}
            form={form}
            handleFormPageChange={handleFormPageChange}
            handleRepairsChange={handleRepairsChange}
            handleNextButton={handleNextButton}
            handleBackButton={handleBackButton}
          />
        )}

        {currentFormPage === "personalInformation" && (
          <PersonalInformation
            handleFormChange={handleFormChange}
            form={form}
            handleFormPageChange={handleFormPageChange}
            userInformation={userInformation}
            handleUserInfoChange={handleUserInfoChange}
            handleFormSubmit={handleFormSubmit}
            handleNextButton={handleNextButton}
            handleBackButton={handleBackButton}
            setCurrentPage={setCurrentPage}
            handleEmail={handleEmail}
          />
        )}

        {currentFormPage === "thankYou" && <ThankYouPage />}
      </Container>
    </div>
  );
};

export default FormSection;
