import React from "react";

import { Form } from "react-bootstrap";

const LivingOnProp = ({
  handleFormChange,
  form,
  handleFormPageChange,
  repairs,
  handleRepairsChange,
  handleNextButton,
  handleBackButton,
}) => {
  return (
    <div>
      <Form
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          alignItems: "center",
        }}
        className="text-success"
      >
        <Form.Label>Is there anyone living on the property? *</Form.Label>

        <div style={{ display: "flex", flexDirection: "column", gap: "3rem" }}>
          <div class="form-check">
            <input
              checked={form.livingOnProperty === "Vacant"}
              type="radio"
              id="Vacant"
              class="form-check-input"
              name="livingOnProperty"
              value="Vacant"
              onChange={(e) => handleFormChange(e)}
            />
            <label htmlFor="Vacant" class="form-check-label">
              No its vacant
            </label>
          </div>

          <div class="form-check">
            <input
              checked={form.livingOnProperty === "Tenant"}
              type="radio"
              id="Tenant"
              class="form-check-input"
              name="livingOnProperty"
              value="Tenant"
              onChange={(e) => handleFormChange(e)}
            />
            <label htmlFor="Tenant" class="form-check-label">
              Tenant occupied
            </label>
          </div>

          <div class="form-check">
            <input
              checked={form.livingOnProperty === "Owner"}
              type="radio"
              id="Owner"
              class="form-check-input"
              name="livingOnProperty"
              value="Owner"
              onChange={(e) => handleFormChange(e)}
            />
            <label htmlFor="Owner" class="form-check-label">
              Owner occupied
            </label>
          </div>
        </div>

        <button
          disabled={form.livingOnProperty === "" ? true : false}
          style={{ width: "10rem" }}
          class="btn btn-success btn-lg"
          onClick={() => handleNextButton("whenFinalize")}
        >
          Next
        </button>
        <button
          onClick={() => handleBackButton("repairs")}
          style={{ width: "10rem", marginBottom: '1rem' }}
          class="btn btn-dark btn-lg"
        >
          Back
        </button>
      </Form>
    </div>
  );
};

export default LivingOnProp;
