import { Form } from "react-bootstrap";

const HowLongOwned = ({
  handleFormChange,
  form,
  handleFormPageChange,
  handleNextButton,
  handleBackButton,
}) => {
  return (
    <div>
      <Form
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          alignItems: "center",
        }}
        className="text-success"
      >
        <Form.Label className="text-center">
          How long have you owned the property in years? *
        </Form.Label>

        <div style={{ display: "flex", flexDirection: "column", gap: "3rem" }}>
          <div class="form-check">
            <input
              checked={form.ownershipLength === "50+"}
              type="radio"
              id="50"
              class="form-check-input"
              name="ownershipLength"
              value="50+"
              onChange={(e) => handleFormChange(e)}
            />
            <label htmlFor="50" class="form-check-label">
              50+
            </label>
          </div>

          <div class="form-check">
            <input
              checked={form.ownershipLength === "30-50"}
              type="radio"
              id="3050"
              class="form-check-input"
              name="ownershipLength"
              value="30-50"
              onChange={(e) => handleFormChange(e)}
            />
            <label htmlFor="3050" class="form-check-label">
              30-50
            </label>
          </div>

          <div class="form-check">
            <input
              checked={form.ownershipLength === "20-29"}
              type="radio"
              id="2029"
              class="form-check-input"
              name="ownershipLength"
              value="20-29"
              onChange={(e) => handleFormChange(e)}
            />
            <label htmlFor="2029" class="form-check-label">
              20-29
            </label>
          </div>

          <div class="form-check">
            <input
              checked={form.ownershipLength === "15-29"}
              type="radio"
              id="1529"
              class="form-check-input"
              name="ownershipLength"
              value="15-29"
              onChange={(e) => handleFormChange(e)}
            />
            <label htmlFor="1529" class="form-check-label">
              15-29
            </label>
          </div>

          <div class="form-check">
            <input
              checked={form.ownershipLength === "10-14"}
              type="radio"
              id="1014"
              class="form-check-input"
              name="ownershipLength"
              value="10-14"
              onChange={(e) => handleFormChange(e)}
            />
            <label htmlFor="1014" class="form-check-label">
              10-14
            </label>
          </div>

          <div class="form-check">
            <input
              checked={form.ownershipLength === "6-9"}
              type="radio"
              id="69"
              class="form-check-input"
              name="ownershipLength"
              value="6-9"
              onChange={(e) => handleFormChange(e)}
            />
            <label htmlFor="69" class="form-check-label">
              6-9
            </label>
          </div>

          <div class="form-check">
            <input
              checked={form.ownershipLength === "2-5"}
              type="radio"
              id="25"
              class="form-check-input"
              name="ownershipLength"
              value="2-5"
              onChange={(e) => handleFormChange(e)}
            />
            <label htmlFor="25" class="form-check-label">
              2-5
            </label>
          </div>

          <div class="form-check">
            <input
              checked={form.ownershipLength === "0-1"}
              type="radio"
              id="01"
              class="form-check-input"
              name="ownershipLength"
              value="0-1"
              onChange={(e) => handleFormChange(e)}
            />
            <label htmlFor="01" class="form-check-label">
              0-1
            </label>
          </div>
        </div>
        <button
          disabled={form.ownershipLength === "" ? true : false}
          style={{ width: "10rem" }}
          class="btn btn-success btn-lg"
          onClick={() => handleNextButton("whySell")}
        >
          Next
        </button>
        <button
          onClick={() => handleBackButton("typeOfHouse")}
          style={{ width: "10rem", marginBottom: '1rem' }}
          class="btn btn-dark btn-lg"
        >
          Back
        </button>
      </Form>
    </div>
  );
};

export default HowLongOwned;
