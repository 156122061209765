import Spinner from "react-bootstrap/Spinner";

import { useLocation } from "react-router-dom";

import ReactPixel from "react-facebook-pixel";

import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

const CalculatingOffer = ({ handleEmail, setCurrentPage }) => {
  const navigate = useNavigate();
  const [showSpinner, setShowSpinner] = useState(true);
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      // handleEmail();
      setShowSpinner(false);
      setShowMessage(true);
    }, 5000);

    return () => clearTimeout(timeout);
  }, []);

  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  ReactPixel.init("1689471784879141", options);
  let source = query.get("tracking_source");

  useEffect(() => {
    if (source === "facebook") {
      ReactPixel.trackCustom("LeadSubmission", {});
    }
  }, [source]);

  return (
    <div className="flex-column d-flex justify-content-center align-items-center vh-100 gap-3">
      {showSpinner && <Spinner animation="border" variant="success" />}
      {showMessage && (
        <div>
          <h1 className="text-success text-center display-1">
            <i class="bi bi-check-circle"></i>
          </h1>
          <h3 className="text-success text-center p-4">
            Good news! We have found you a cash offer and someone will contact
            you shortly.
          </h3>
        </div>
      )}
      {showSpinner && (
        <h3 className="text-muted text-center">
          Please wait while we find you a{" "}
          <span className="text-success fw-bold">CASH</span> offer
        </h3>
      )}
    </div>
  );
};

export default CalculatingOffer;
