import { Form } from "react-bootstrap";

const ListedWithAgent = ({
  handleFormChange,
  form,
  handleFormPageChange,
  handleNextButton,
  handleBackButton,
}) => {
  return (
    <div>
      <Form
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          alignItems: "center",
        }}
        className="text-success"
      >
        <Form.Label>
          Is your property listed with a real estate agent?
        </Form.Label>

        <div style={{ display: "flex", flexDirection: "column", gap: "3rem" }}>
          <div class="form-check">
            <input
              checked={form.isPropertyListedWithAgent === "no"}
              type="radio"
              id="poor"
              class="form-check-input"
              name="isPropertyListedWithAgent"
              value="no"
              onChange={(e) => handleFormChange(e)}
            />
            <label htmlFor="poor" class="form-check-label">
              No it's not listed
            </label>
          </div>

          <div class="form-check">
            <input
              checked={form.isPropertyListedWithAgent === "yes"}
              type="radio"
              id="question"
              class="form-check-input"
              name="isPropertyListedWithAgent"
              value="yes"
              onChange={(e) => handleFormChange(e)}
            />
            <label htmlFor="question" class="form-check-label">
              Yes it's listed
            </label>
          </div>
        </div>

        <button
          disabled={form.isPropertyListedWithAgent === "" ? true : false}
          style={{ width: "10rem" }}
          class="btn btn-success btn-lg"
          onClick={() => handleNextButton("typeOfHouse")}
        >
          Next
        </button>
        <button
          onClick={() => handleBackButton("houseCondition")}
          style={{ width: "10rem", marginBottom: '1rem' }}
          class="btn btn-dark btn-lg"
        >
          Back
        </button>
      </Form>
    </div>
  );
};

export default ListedWithAgent;
