

import { Container, Col, Row, Button } from "react-bootstrap";

import logo from '../images/CFH_LogoV2.png'

// import logo from "../images/CFH_LogoV2.png";

const ConfirmPage = ({ selectedAddress, handleAddressConfirm }) => {
  return (
    <div>
      <Container className="my-5">
        <Row>
          <Col>
            <img src={logo} style={{ width: "200px" }} alt="" />
          </Col>
          <Col>
            <p>
              Over 2,500 families trusted Cash4Homes offers to begin their home
              selling process
            </p>
          </Col>
        </Row>
      </Container>

      <Container className="my-5">
        <h3 className="text-center">Is this address correct?</h3>
        <h1 className="text-center text-success">{selectedAddress}</h1>
      </Container>

      <Container style={{ textAlign: 'center' }}>
        <Row className="justify-content-center">
          <Col>
            <Button onClick={() => handleAddressConfirm('no')} className="bg-light text-dark btn-md border-black">FIX ADDRESS</Button>
          </Col>

          <Col>
            <Button style={{fontSize: '15px'}} onClick={() => handleAddressConfirm('yes')} className="bg-success btn-md border-success">YES, THAT'S RIGHT</Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ConfirmPage;
