import { Navbar, Container, Nav, Row, Col, Form, Stack } from "react-bootstrap";

//!Hello wolrd

//!Styles
import "./Style.css";
import logo from "../images/CFH_LogoV2.png";

//!Icons

import { useLocation, useSearchParams } from "react-router-dom";

//!Images
import couplePic from "../images/couple_at_computer.jpeg";
import step1 from "../images/step1.jpeg";
import step2 from "../images/step2.jpeg";
import step3 from "../images/step3.jpeg";

import houseLogo from "../images/houseLogo.png";
import { useEffect, useState } from "react";

const Homepage = ({ handleSubmitAddress, bootstrapRef, setCurrentPage }) => {
  const handleMouseLeave = (e) => {
    if (
      e.clientY <= 0 ||
      e.clientX <= 0 ||
      e.clientX >= window.innerWidth ||
      e.clientY >= window.innerHeight
    ) {
      console.log("Mouse has left screen ");
    }
  };

  const [showFacebookNumber, setShowFacebookNumber] = useState(false);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();
  let source = query.get("tracking_source");

  useEffect(() => {
    if (source === "facebook") {
      setShowFacebookNumber(true);
    } else {
      setShowFacebookNumber(false)
    }
  }, [source]);

  const originalNumber = "tel:+17852602685"
  const facebookNumber = "tel:+17852780103"

  return (
    <div onMouseLeave={handleMouseLeave}>
      <div>
        <Navbar className="fixed-top" bg="light" data-bs-theme="dark">
          <Container className="justify-content-between">
            <img
              className="d-none d-sm-block"
              style={{ width: "300px" }}
              src={logo}
              alt=""
            />

            <img
              className="d-block d-sm-none"
              style={{ width: "50px" }}
              src={houseLogo}
              alt=""
            />

            <div>
              <Nav className="me-auto flex-row justify-content-center align-items-center gap-3">
                <div>
                  <button
                    onClick={() => setCurrentPage("getOffer")}
                    className="btn btn-success btn-lg"
                    style={{ marginRight: "70px" }}
                  >
                    GET OFFER
                  </button>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    gap: "1rem",
                  }}
                >
                  <a href={showFacebookNumber ? facebookNumber : originalNumber}>
                    <i
                      style={{ fontSize: "1.9rem", color: "rgb(25,135,83)" }}
                      class="bi bi-telephone-fill"
                    ></i>
                  </a>

                  <div>
                    <p
                      className="text-success d-none d-sm-block"
                      style={{ fontSize: "1.9rem", fontWeight: "bold" }}
                    >
                      {showFacebookNumber ? '785-278-0103' : '785-260-2685'}
                    </p>
                  </div>
                </div>
              </Nav>
            </div>
          </Container>
        </Navbar>
      </div>
      <header style={{ height: "800px" }}>
        <div
          style={{
            height: "800px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          className="container-fluid bg-black p-3 bg-opacity-75 text-white"
        >
          <div className="p-4 container-lg">
            <h1 className="display-4  fw-bold">SELL YOUR HOUSE FAST</h1>
            <h1 className="display-4 fw-bold">
              FOR <span className="text-success">CASH</span>{" "}
            </h1>

            <Form className="my-5">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="fs-5 text-light fw-bold p-2">
                  Start by getting your Cash Offer in a few easy clicks
                </Form.Label>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Control
                      type="text"
                      placeholder="Enter address..."
                      className="mb-2 form-control-lg"
                      // onChange={handleInputChange}
                      name="Street"
                      data-user="hello"
                      ref={bootstrapRef}
                    />
                  </div>

                  <div className="col">
                    <button
                      onClick={handleSubmitAddress}
                      className="btn btn-success btn-lg"
                    >
                      Get your offer!
                    </button>
                  </div>
                </div>
              </Form.Group>
            </Form>
          </div>
        </div>
      </header>
      <Container className="fluid">
        <Row className="text-center my-5 justify-content-center fluid flex-wrap">
          <Col className="m-4 border border-success col-lg-3 rounded bg-light">
            <h1 style={{ fontSize: "60px", color: "green" }}>
              <i class="bi bi-cash-coin"></i>
            </h1>
            <h2>$0 Fees, our service is 100% free</h2>
            <p>
              Say goodbye to the hassle of traditional home selling with fast
              and fair cash offers from Cash4Homes buyers.
            </p>
          </Col>
          <Col className="m-4 border border-success col-lg-3 rounded bg-light">
            <h1 style={{ fontSize: "60px", color: "green" }}>
              <i class="bi bi-tools"></i>
            </h1>
            <h2>Sell as-is, no repairs or realtor needed</h2>
            <p>
              Sit back and relax while we handle all the hassle of selling your
              home.
            </p>
          </Col>
          <Col className="m-4 border border-success col-lg-3 rounded bg-light">
            <h1 style={{ fontSize: "60px", color: "green" }}>
              <i class="bi bi-clock-fill"></i>
            </h1>
            <h2>Close as quick or as slow as you want</h2>
            <p>
              Experience a transparent and straightforward selling process with
              Cash4Homes Offer.
            </p>
          </Col>
        </Row>
      </Container>

      <Container className="text-center">
        <button
          onClick={() => setCurrentPage("getOffer")}
          className="btn btn-success btn-lg"
        >
          GET OFFER
        </button>
      </Container>

      <Container>
        <Stack className="text-center my-5">
          <h1 className="display-5">Sell your home fast.</h1>
          <h1 className="text-success">Dodge the headaches.</h1>

          <p style={{ fontSize: "24px" }} className="lead">
            We’ve optimized your home selling process to avoid all the headache
            that comes with selling the old school way
          </p>
        </Stack>
      </Container>

      <Container>
        <Row className="text-center gap-5">
          <Col>
            <img
              src={step1}
              style={{ width: "300px", height: "200px", borderRadius: "10px" }}
              alt=""
            />
            <h2 className="text-success">Step 1</h2>
            <h4>Request your offer online, no prep work or showings</h4>
            <p style={{ fontSize: "15px" }}>
              Tell us about your no longer wanted house or property that you
              want to sell here on our site or by calling us.
            </p>
          </Col>

          <Col>
            <img
              src={step2}
              style={{ width: "300px", height: "200px", borderRadius: "10px" }}
              alt=""
            />
            <h2 className="text-success">Step 2</h2>
            <h4>We will contact you</h4>
            <p style={{ fontSize: "15px" }}>
              We will contact you right away to set up a quick appointment.
            </p>
          </Col>

          <Col>
            <img
              src={step3}
              style={{ width: "300px", height: "200px", borderRadius: "10px" }}
              alt=""
            />
            <h2 className="text-success">Step 3</h2>
            <h4>We will offer you a fair cash offer</h4>
            <p style={{ fontSize: "15px" }}>
              We will propose to you a fair, written, no-obligation cash offer
              on your house or property.
            </p>

            <button
              onClick={() => setCurrentPage("getOffer")}
              className="btn btn-success btn-lg m-2"
            >
              GET OFFER
            </button>
          </Col>
        </Row>
      </Container>
      <footer>
        <Container fluid className="bg-dark">
          <Stack className="justify-content-center text-center" gap={3}>
            <div className="p-2">
              {" "}
              <img
                src={logo}
                style={{ width: "150px", marginTop: "15px" }}
                alt=""
              />{" "}
            </div>
            <div className="p-2 flex-column justify-content-center align-items-center">
              <p className="fw-bold text-light"> Talk to us</p>

              <p className="text-light">
                <i
                  style={{ fontSize: "1rem" }}
                  class="bi bi-telephone-fill"
                ></i>{" "}
                785-260-2685
              </p>
              <p className="text-light">
                <i
                  style={{ fontSize: "1rem" }}
                  class="bi bi-envelope-at-fill"
                ></i>{" "}
                cash4homesoffer@gmail.com
              </p>
            </div>
            <hr style={{ border: "1px solid white", margin: "20px 0" }} />

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                gap: "2rem",
              }}
            >
              <a
                target="_blank"
                href="https://www.facebook.com/profile.php?id=61550703154463"
              >
                <i class="bi bi-facebook"></i>
              </a>

              <a href="">
                <i class="bi bi-twitter"></i>
              </a>
            </div>

            <div>
              <p style={{ fontSize: "13px" }} className="text-white">
                2023 Cash 4 Homes. All Rights Reserved.
              </p>

              <p style={{ fontSize: "13px" }} className="text-white">
                This site is not a part of the Facebook website or Facebook Inc.
                Additionally, This site is NOT endorsed by Facebook in any way.
                FACEBOOK is a trademark of FACEBOOK, Inc.
              </p>
            </div>
          </Stack>
        </Container>
      </footer>
    </div>
  );
};

export default Homepage;
