import { Form } from "react-bootstrap";

const TypeOfHouse = ({
  handleFormChange,
  form,
  handleFormPageChange,
  handleNextButton,
  handleBackButton,
}) => {
  return (
    <div>
      <Form
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          alignItems: "center",
        }}
        className="text-success"
      >
        <Form.Label>Type of house *</Form.Label>

        <div style={{ display: "flex", flexDirection: "column", gap: "3rem" }}>
          <div class="form-check">
            <input
              checked={form.typeOfHouse === "Single family"}
              type="radio"
              id="singleFam"
              class="form-check-input"
              name="typeOfHouse"
              value="Single family"
              onChange={(e) => handleFormChange(e)}
            />
            <label htmlFor="singleFam" class="form-check-label">
              Single family
            </label>
          </div>

          <div class="form-check">
            <input
              checked={form.typeOfHouse === "Ranch"}
              type="radio"
              id="ranch"
              class="form-check-input"
              name="typeOfHouse"
              value="Ranch"
              onChange={(e) => handleFormChange(e)}
            />
            <label htmlFor="ranch" class="form-check-label">
              Ranch
            </label>
          </div>
          <div class="form-check">
            <input
              checked={form.typeOfHouse === "2-Story"}
              type="radio"
              id="2story"
              class="form-check-input"
              name="typeOfHouse"
              value="2-Story"
              onChange={(e) => handleFormChange(e)}
            />
            <label htmlFor="2story" class="form-check-label">
              2-Story
            </label>
          </div>

          <div class="form-check">
            <input
              checked={form.typeOfHouse === "Mobile home owned land"}
              type="radio"
              id="mobileHome"
              class="form-check-input"
              name="typeOfHouse"
              value="Mobile home owned land"
              onChange={(e) => handleFormChange(e)}
            />
            <label htmlFor="mobileHome" class="form-check-label">
              Mobile home owned land
            </label>
          </div>

          <div class="form-check">
            <input
              checked={form.typeOfHouse === "Mobile home rented land"}
              type="radio"
              id="mobileRented"
              class="form-check-input"
              name="typeOfHouse"
              value="Mobile home rented land"
              onChange={(e) => handleFormChange(e)}
            />
            <label htmlFor="mobileRented" class="form-check-label">
              Mobile home rented land
            </label>
          </div>

          <div class="form-check">
            <input
              checked={form.typeOfHouse === "Multifamily"}
              type="radio"
              id="multiFamily"
              class="form-check-input"
              name="typeOfHouse"
              value="Multifamily"
              onChange={(e) => handleFormChange(e)}
            />
            <label htmlFor="multiFamily" class="form-check-label">
              Multifamily
            </label>
          </div>

          <div class="form-check">
            <input
              checked={form.typeOfHouse === "Bungalow"}
              type="radio"
              id="bungalow"
              class="form-check-input"
              name="typeOfHouse"
              value="Bungalow"
              onChange={(e) => handleFormChange(e)}
            />
            <label htmlFor="bungalow" class="form-check-label">
              Bungalow
            </label>
          </div>

          <div class="form-check">
            <input
              checked={form.typeOfHouse === "Cottage"}
              type="radio"
              id="cottage"
              class="form-check-input"
              name="typeOfHouse"
              value="Cottage"
              onChange={(e) => handleFormChange(e)}
            />
            <label htmlFor="cottage" class="form-check-label">
              Cottage
            </label>
          </div>

          <div class="form-check">
            <input
              checked={form.typeOfHouse === "Townhouse"}
              type="radio"
              id="townHouse"
              class="form-check-input"
              name="typeOfHouse"
              value="Townhouse"
              onChange={(e) => handleFormChange(e)}
            />
            <label htmlFor="townHouse" class="form-check-label">
              Townhouse
            </label>
          </div>

          <div class="form-check">
            <input
              checked={form.typeOfHouse === "Condominium"}
              type="radio"
              id="condo"
              class="form-check-input"
              name="typeOfHouse"
              value="Condominium"
              onChange={(e) => handleFormChange(e)}
            />
            <label htmlFor="condo" class="form-check-label">
              Condominium
            </label>
          </div>

          <div class="form-check">
            <input
              checked={form.typeOfHouse === "Duplex"}
              type="radio"
              id="duplex"
              class="form-check-input"
              name="typeOfHouse"
              value="Duplex"
              onChange={(e) => handleFormChange(e)}
            />
            <label htmlFor="duplex" class="form-check-label">
              Duplex
            </label>
          </div>

          <div class="form-check">
            <input
              checked={form.typeOfHouse === "Farmhouse"}
              type="radio"
              id="farmhouse"
              class="form-check-input"
              name="typeOfHouse"
              value="Farmhouse"
              onChange={(e) => handleFormChange(e)}
            />
            <label htmlFor="farmhouse" class="form-check-label">
              Farmhouse
            </label>
          </div>

          <div class="form-check">
            <input
              checked={form.typeOfHouse === "Split-level home"}
              type="radio"
              id="splitHome"
              class="form-check-input"
              name="typeOfHouse"
              value="Split-level home"
              onChange={(e) => handleFormChange(e)}
            />
            <label htmlFor="splitHome" class="form-check-label">
              Split-level home
            </label>
          </div>

          <div class="form-check">
            <input
              checked={form.typeOfHouse === "Land"}
              type="radio"
              id="land"
              class="form-check-input"
              name="typeOfHouse"
              value="Land"
              onChange={(e) => handleFormChange(e)}
            />
            <label htmlFor="land" class="form-check-label">
              Land
            </label>
          </div>
        </div>

        <button
          disabled={form.typeOfHouse === "" ? true : false}
          style={{ width: "10rem" }}
          class="btn btn-success btn-lg"
          onClick={() => handleNextButton("howLongOwned")}
        >
          Next
        </button>
        <button
          onClick={() => handleBackButton("listedWithAgent")}
          style={{ width: "10rem", marginBottom: '1rem' }}
          class="btn btn-dark btn-lg"
        >
          Back
        </button>
      </Form>
    </div>
  );
};

export default TypeOfHouse;
