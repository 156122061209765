import { useState, useEffect } from "react";

import FormSection from "./Pages/FormPage/FormSection";

import Homepage from "./Pages/Homepage";

import ConfirmPage from "./Pages/ConfirmPage";

import CalculatingOffer from "./Pages/CalculatingOffer";

import GetOfferPage from "./Pages/GetOfferPage";

import "bootstrap/dist/css/bootstrap.min.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { usePlacesWidget } from "react-google-autocomplete";

import ReactGA from "react-ga4";
import EditAddress from "./Pages/EditAddress";

function App() {
  ReactGA.initialize("G-Z259X0HKYG");
  useEffect(() => {
    if (window.location.pathname === "/cashoffer") {
      setCurrentPage("");
    }
  }, []);
  const [form, setForm] = useState({
    houseCondition: "",
    isPropertyListedWithAgent: "",
    typeOfHouse: "",
    ownershipLength: "",
    whySell: "",
    livingOnProperty: "",
    whenFinalize: "",
  });

  const [repairs, setRepairs] = useState([]);

  const [userInformation, setUserInformation] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
  });

  const [parsedAddress, setParsedAddress] = useState({
    street: "",
    city: "",
    state: "",
    zip: "",
  });

  const handleParsingAddress = () => {
    const addressString = "4352 S Farm Rd 85, Republic, MO 65738, USA";

    // Regular expression to match the address components
    const addressRegex = /(\d+)\s(.+),\s(.+),\s(\w+)\s(\d+),\s(.+)/;
    const matches = selectedAddress.match(addressRegex);

    if (matches) {
      const [, streetNumber, streetName, city, state, postalCode, country] =
        matches;

      // Update the state with the parsed address components
      const parsedAddressData = {
        street: `${streetNumber} ${streetName}`,
        city: city,
        state: state,
        zip: postalCode,
      };

      setParsedAddress(parsedAddressData);
      console.log(parsedAddress);
      setCurrentPage("fixAddress");
    } else {
      // Handle invalid address format
      console.error("Invalid address format");
    }
  };

  const handleFixAddressChange = (e) => {
    const { name, value } = e.target;

    setParsedAddress((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [progessBar, setProgressBar] = useState(12);

  function emailBody() {
    return (
      `<strong>Address:</strong> ${selectedAddress}<br><br>` +
      `<strong>House Condition:</strong> ${form.houseCondition}<br><br>` +
      `<strong>Why are you selling?:</strong> ${form.whySell}<br><br>` +
      `<strong>Living On Property:</strong> ${form.livingOnProperty}<br><br>` +
      `<strong>Would like to finalize:</strong> ${form.whenFinalize}<br><br>` +
      `<strong>Property With Agent:</strong> ${form.isPropertyListedWithAgent}<br><br>` +
      `<strong>Type Of House:</strong> ${form.typeOfHouse}<br><br>` +
      `<strong>Ownership Length:</strong> ${form.ownershipLength}<br><br>` +
      `<strong>House Condition:</strong> ${repairs}<br><br>` +
      `<strong>First name:</strong> ${userInformation.firstName}<br><br>` +
      `<strong>Last name:</strong> ${userInformation.lastName}<br><br>` +
      `<strong>Phone number:</strong> ${userInformation.phoneNumber}<br><br>` +
      `<strong>Email:</strong> ${userInformation.email}<br><br>`
    );
  }

  const handleEmail = () => {
    if (window.Email) {
      window.Email.send({
        SecureToken: "6538914b-1c67-421b-aa5a-6d77a7fa3dd9",
        To: "ptaylor@blueviewgroup.com",
        From: "ptaylor@blueviewgroup.com",
        Subject: "Cash4Homes Website Lead",
        Body: emailBody(),
      }).then((message) => console.log(message));
    }
  };

  const handleUserInfoChange = (e) => {
    const { name, value } = e.target;
    setUserInformation((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [selectedAddress, setSelectedAddress] = useState("");

  const [currentPage, setCurrentPage] = useState("homePage");

  const { ref: bootstrapRef } = usePlacesWidget({
    apiKey: "AIzaSyArSWpxa0qMV7l9pDEMRoLCn6s4Zecu7rw",
    options: {
      componentRestrictions: { country: "us" },
      types: ["address"],
    },
    onPlaceSelected: (place) => setSelectedAddress(place.formatted_address),
  });

  const handleSubmitAddress = (e) => {
    e.preventDefault();
    if (selectedAddress === "") {
      return;
    } else {
      setCurrentPage("confirmAddress");
    }
    const eventObject = {
      category: "Button",
      action: "Click",
      label: "Next Button Clicked",
    };
    ReactGA.event(eventObject);
  };

  const handleAddressConfirm = (answer) => {
    answer === "yes" ? setCurrentPage("formPage") : handleParsingAddress();
  };

  const handleEditAddressSubmit = () => {
    setSelectedAddress(
      `${parsedAddress.street}, ${parsedAddress.city}, ${parsedAddress.state} ${parsedAddress.zip}, USA`
    );

    setCurrentPage('confirmAddress')
  };

  const [currentFormPage, setCurrentFormPage] = useState("houseCondition");

  const handleFormChange = (e) => {
    const { name, value } = e.target;

    setForm((prevState) => {
      const updatedForm = {
        ...prevState,
        [name]: value,
      };

      return updatedForm;
    });

    // setForm((prevState) => {
    //   const updatedForm = {
    //     ...prevState,
    //     [name]: prevState[name] !== "" ? "" : value,
    //   };

    //   if (updatedForm[name] !== "") {
    //     setCurrentFormPage(page);
    //   }

    //   return updatedForm;
    // });
  };

  // When user checks a repairs that is needed > it needs to be added to the repairs array
  // When user wants to remove a repair > need to check array for that repair and remove it
  // For checkbox to be checked, we need to check the array to see if it includes that repair

  const handleRepairsChange = (e) => {
    const { name, value } = e.target;
    if (repairs.includes(value)) {
      setRepairs((prevState) => {
        return prevState.filter((rep) => rep !== value);
      });
    } else {
      setRepairs((prevState) => {
        return [...prevState, value];
      });
    }
  };

  const handleFormPageChange = (page) => {
    setCurrentFormPage(page);
  };

  const handleNextButton = (page) => {
    setCurrentFormPage(page);
    setProgressBar(progessBar + 12);
  };

  const handleBackButton = (page) => {
    setCurrentFormPage(page);
    setProgressBar(progessBar - 12);
  };

  // const handleFormSubmit = (e) => {
  //   e.preventDefault();

  //   if (
  //     userInformation.firstName === "" ||
  //     userInformation.lastName === "" ||
  //     userInformation.email === "" ||
  //     userInformation.phoneNumber === ""
  //   ) {
  //     return;
  //   } else {
  //     setCurrentPage("calculatingOffer")
  //   }
  // };

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            path="/cashoffer"
            element={
              <CalculatingOffer
                handleEmail={handleEmail}
                setCurrentPage={setCurrentPage}
              />
            }
          />
        </Routes>
        {currentPage === "homePage" && (
          <Homepage
            handleSubmitAddress={handleSubmitAddress}
            bootstrapRef={bootstrapRef}
            setCurrentPage={setCurrentPage}
          />
        )}

        {currentPage === "confirmAddress" && (
          <ConfirmPage
            handleAddressConfirm={handleAddressConfirm}
            selectedAddress={selectedAddress}
          />
        )}

        {currentPage === "formPage" && (
          <FormSection
            handleFormChange={handleFormChange}
            currentFormPage={currentFormPage}
            setCurrentFormPage={setCurrentFormPage}
            handleFormPageChange={handleFormPageChange}
            form={form}
            userInformation={userInformation}
            handleUserInfoChange={handleUserInfoChange}
            // handleFormSubmit={handleFormSubmit}
            handleRepairsChange={handleRepairsChange}
            repairs={repairs}
            progessBar={progessBar}
            handleNextButton={handleNextButton}
            handleBackButton={handleBackButton}
            setCurrentPage={setCurrentPage}
            handleEmail={handleEmail}
          />
        )}

        {currentPage === "getOffer" && (
          <GetOfferPage
            setSelectedAddress={setSelectedAddress}
            handleSubmitAddress={handleSubmitAddress}
          />
        )}

        {currentPage === "fixAddress" && (
          <EditAddress
            parsedAddress={parsedAddress}
            handleFixAddressChange={handleFixAddressChange}
            setCurrentPage={setCurrentPage}
            handleEditAddressSubmit={handleEditAddressSubmit}
          />
        )}

        {/* {currentPage === "calculatingOffer" && (
          <CalculatingOffer handleEmail={handleEmail} />
        )} */}
      </Router>
    </div>
  );
}

export default App;
