import React from "react";
import { Form } from "react-bootstrap";

const RepairsTouchups = ({
  handleFormChange,
  form,
  handleFormPageChange,
  repairs,
  handleRepairsChange,
  handleNextButton,
  handleBackButton,
}) => {
  return (
    <div>
      <Form
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          alignItems: "center",
        }}
        className="text-success"
      >
        <Form.Label style={{ textAlign: "center" }}>
          What repairs and touchups are needed to get the house to perfect
          condition *
        </Form.Label>

        <div style={{ display: "flex", flexDirection: "column", gap: "3rem" }}>
          <div class="form-check">
            <input
              checked={repairs.includes("Structural Problems")}
              type="checkbox"
              id="Structural Problems"
              class="form-check-input"
              name="repairsAndTouchups"
              value="Structural Problems"
              onChange={(e) => handleRepairsChange(e)}
            />
            <label htmlFor="Structural Problems" class="form-check-label">
              Structural problems
            </label>
          </div>

          <div class="form-check">
            <input
              checked={repairs.includes("Flood/Storm/Fire Damage")}
              type="checkbox"
              id="Flood/Storm/Fire Damage"
              class="form-check-input"
              name="repairsAndTouchups"
              value="Flood/Storm/Fire Damage"
              onChange={(e) => handleRepairsChange(e)}
            />
            <label htmlFor="Flood/Storm/Fire Damage" class="form-check-label">
              Flood/Storm/Fire Damage
            </label>
          </div>

          <div class="form-check">
            <input
              checked={repairs.includes("Roof needs replacement")}
              type="checkbox"
              id="Roof needs replacement"
              class="form-check-input"
              name="repairsAndTouchups"
              value="Roof needs replacement"
              onChange={(e) => handleRepairsChange(e)}
            />
            <label htmlFor="Roof needs replacement" class="form-check-label">
              Roof needs replacement
            </label>
          </div>

          <div class="form-check">
            <input
              checked={repairs.includes("Foundation problems")}
              type="checkbox"
              id="Foundation problems"
              class="form-check-input"
              name="repairsAndTouchups"
              value="Foundation problems"
              onChange={(e) => handleRepairsChange(e)}
            />
            <label htmlFor="Foundation problems" class="form-check-label">
              Foundation problems
            </label>
          </div>

          <div class="form-check">
            <input
              checked={repairs.includes("Bathroom(s) need work")}
              type="checkbox"
              id="Bathroom(s) need work"
              class="form-check-input"
              name="repairsAndTouchups"
              value="Bathroom(s) need work"
              onChange={(e) => handleRepairsChange(e)}
            />
            <label htmlFor="Bathroom(s) need work" class="form-check-label">
              Bathroom(s) need work
            </label>
          </div>

          <div class="form-check">
            <input
              checked={repairs.includes("Paint inside")}
              type="checkbox"
              id="Paint inside"
              class="form-check-input"
              name="repairsAndTouchups"
              value="Paint inside"
              onChange={(e) => handleRepairsChange(e)}
            />
            <label htmlFor="Paint inside" class="form-check-label">
              Paint inside
            </label>
          </div>

          <div class="form-check">
            <input
              checked={repairs.includes("Brand new home")}
              type="checkbox"
              id="Brand new home"
              class="form-check-input"
              name="repairsAndTouchups"
              value="Brand new home"
              onChange={(e) => handleRepairsChange(e)}
            />
            <label htmlFor="Brand new home" class="form-check-label">
              It's a brand new home and needs nothing
            </label>
          </div>

          <div class="form-check">
            <input
              checked={repairs.includes("Boiler/water heater")}
              type="checkbox"
              id="Boiler/water heater"
              class="form-check-input"
              name="repairsAndTouchups"
              value="Boiler/water heater"
              onChange={(e) => handleRepairsChange(e)}
            />
            <label htmlFor="Boiler/water heater" class="form-check-label">
              Boiler/water heater
            </label>
          </div>

          <div class="form-check">
            <input
              checked={repairs.includes("Landscaping")}
              type="checkbox"
              id="Landscaping"
              class="form-check-input"
              name="repairsAndTouchups"
              value="Landscaping"
              onChange={(e) => handleRepairsChange(e)}
            />
            <label htmlFor="Landscaping" class="form-check-label">
              Landscaping
            </label>
          </div>

          <div class="form-check">
            <input
              checked={repairs.includes("Electrical")}
              type="checkbox"
              id="Electrical"
              class="form-check-input"
              name="repairsAndTouchups"
              value="Electrical"
              onChange={(e) => handleRepairsChange(e)}
            />
            <label htmlFor="Electrical" class="form-check-label">
              Electrical
            </label>
          </div>

          <div class="form-check">
            <input
              checked={repairs.includes("Plumbing")}
              type="checkbox"
              id="Plumbing"
              class="form-check-input"
              name="repairsAndTouchups"
              value="Plumbing"
              onChange={(e) => handleRepairsChange(e)}
            />
            <label htmlFor="Plumbing" class="form-check-label">
              Plumbing
            </label>
          </div>

          <div class="form-check">
            <input
              checked={repairs.includes("Flooring")}
              type="checkbox"
              id="Flooring"
              class="form-check-input"
              name="repairsAndTouchups"
              value="Flooring"
              onChange={(e) => handleRepairsChange(e)}
            />
            <label htmlFor="Flooring" class="form-check-label">
              Flooring
            </label>
          </div>

          <div class="form-check">
            <input
              checked={repairs.includes("Kitchen cabinets")}
              type="checkbox"
              id="Kitchen cabinets"
              class="form-check-input"
              name="repairsAndTouchups"
              value="Kitchen cabinets"
              onChange={(e) => handleRepairsChange(e)}
            />
            <label htmlFor="Kitchen cabinets" class="form-check-label">
              Kitchen cabinets
            </label>
          </div>

          <div class="form-check">
            <input
              checked={repairs.includes("Kitchen appliances")}
              type="checkbox"
              id="Kitchen appliances"
              class="form-check-input"
              name="repairsAndTouchups"
              value="Kitchen appliances"
              onChange={(e) => handleRepairsChange(e)}
            />
            <label htmlFor="Kitchen appliances" class="form-check-label">
              Kitchen appliances
            </label>
          </div>

          <div class="form-check">
            <input
              checked={repairs.includes("Paint outside")}
              type="checkbox"
              id="Paint outside"
              class="form-check-input"
              name="repairsAndTouchups"
              value="Paint outside"
              onChange={(e) => handleRepairsChange(e)}
            />
            <label htmlFor="Paint outside" class="form-check-label">
              Paint outside
            </label>
          </div>

          <div class="form-check">
            <input
              checked={repairs.includes("Air conditioning")}
              type="checkbox"
              id="Air conditioning"
              class="form-check-input"
              name="repairsAndTouchups"
              value="Air conditioning"
              onChange={(e) => handleRepairsChange(e)}
            />
            <label htmlFor="Air conditioning" class="form-check-label">
              Air conditioning
            </label>
          </div>
        </div>

        <button
          disabled={form.whySell === "" ? true : false}
          style={{ width: "10rem" }}
          class="btn btn-success btn-lg"
          onClick={() => handleNextButton("livingOnProp")}
        >
          Next
        </button>
        <button
          onClick={() => handleBackButton("whySell")}
          style={{ width: "10rem", marginBottom: '1rem' }}
          class="btn btn-dark btn-lg"
        >
          Back
        </button>
      </Form>
    </div>
  );
};

export default RepairsTouchups;
