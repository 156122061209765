import React from "react";

import { Form } from "react-bootstrap";

const WhenFinalize = ({
  handleFormChange,
  form,
  handleFormPageChange,
  repairs,
  handleRepairsChange,
  handleNextButton,
  handleBackButton,
}) => {
  return (
    <div>
      <Form
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          alignItems: "center",
        }}
        className="text-success"
      >
        <Form.Label>
          When do you want to finalize the sale of your home? *
        </Form.Label>

        <div style={{ display: "flex", flexDirection: "column", gap: "3rem" }}>
          <div class="form-check">
            <input
              checked={form.whenFinalize === "ASAP"}
              type="radio"
              id="ASAP"
              class="form-check-input"
              name="whenFinalize"
              value="ASAP"
              onChange={(e) => handleFormChange(e)}
            />
            <label htmlFor="ASAP" class="form-check-label">
              ASAP
            </label>
          </div>

          <div class="form-check">
            <input
              checked={form.whenFinalize === "1 month"}
              type="radio"
              id="1 month"
              class="form-check-input"
              name="whenFinalize"
              value="1 month"
              onChange={(e) => handleFormChange(e)}
            />
            <label htmlFor="1 month" class="form-check-label">
              1 month
            </label>
          </div>

          <div class="form-check">
            <input
              checked={form.whenFinalize === "2-3 months"}
              type="radio"
              id="2-3 months"
              class="form-check-input"
              name="whenFinalize"
              value="2-3 months"
              onChange={(e) => handleFormChange(e)}
            />
            <label htmlFor="2-3 months" class="form-check-label">
              2-3 months
            </label>
          </div>
        </div>

        <button
          disabled={form.whenFinalize === "" ? true : false}
          style={{ width: "10rem" }}
          class="btn btn-success btn-lg"
          onClick={() => handleNextButton("personalInformation")}
        >
          Next
        </button>
        <button
          onClick={() => handleBackButton("livingOnProp")}
          style={{ width: "10rem", marginBottom: '1rem' }}
          class="btn btn-dark btn-lg"
        >
          Back
        </button>
      </Form>
    </div>
  );
};

export default WhenFinalize;
