import { Form } from "react-bootstrap";

const PropertyOwnership = ({
  handleFormChange,
  form,
  handleFormPageChange,
}) => {
  return (
    <div>
      <Form
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          alignItems: "center",
        }}
        className="text-success"
      >
        <Form.Label style={{ textAlign: "center" }}>
          Are you the owner of this property or an agent/wholesaler selling this
          property? *
        </Form.Label>

        <div style={{ display: "flex", flexDirection: "column", gap: "3rem" }}>
          <div class="form-check">
            <input
              checked={form.propertySeller === "homeOwner"}
              type="radio"
              id="poor"
              class="form-check-input"
              name="propertySeller"
              value="homeOwner"
              onChange={(e) => handleFormChange(e, "listedWithAgent")}
            />
            <label htmlFor="poor" class="form-check-label">
              Yes, I own this property
            </label>
          </div>

          <div class="form-check">
            <input
              checked={form.propertySeller === "agent/wholesaler"}
              type="radio"
              id="question"
              class="form-check-input"
              name="propertySeller"
              value="agent/wholesaler"
              onChange={(e) => handleFormChange(e, "listedWithAgent")}
            />
            <label htmlFor="question" class="form-check-label">
              Agent/wholesaler
            </label>
          </div>
        </div>

        {/* <button style={{ width: "10rem" }} class="btn btn-success btn-lg">
          Next
        </button> */}
        <button
          onClick={() => handleFormPageChange("houseCondition")}
          style={{ width: "10rem" }}
          class="btn btn-dark btn-lg"
        >
          Back
        </button>
      </Form>
    </div>
  );
};

export default PropertyOwnership;
