import { Form } from "react-bootstrap";

const HouseCondition = ({
  handleFormChange,
  form,
  handleFormPageChange,
  handleNextButton,
}) => {
  return (
    <div>
      <Form
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          alignItems: "center",
        }}
        className="text-success"
      >
        <Form.Label>
          What is the current condition of the property? *
        </Form.Label>

        <div style={{ display: "flex", flexDirection: "column", gap: "3rem" }}>
          <div class="form-check">
            <input
              checked={form.houseCondition === "terrible"}
              type="radio"
              id="terrible"
              class="form-check-input"
              name="houseCondition"
              value="terrible"
              onChange={(e) => handleFormChange(e)}
            />
            <label htmlFor="terrible" class="form-check-label">
              Terrible
            </label>
          </div>

          <div class="form-check">
            <input
              checked={form.houseCondition === "poor"}
              type="radio"
              id="poor"
              class="form-check-input"
              name="houseCondition"
              value="poor"
              onChange={(e) => handleFormChange(e)}
            />
            <label htmlFor="poor" class="form-check-label">
              Poor
            </label>
          </div>

          <div class="form-check">
            <input
              checked={form.houseCondition === "fair"}
              type="radio"
              id="question"
              class="form-check-input"
              name="houseCondition"
              value="fair"
              onChange={(e) => handleFormChange(e)}
            />
            <label htmlFor="question" class="form-check-label">
              Fair
            </label>
          </div>

          <div class="form-check">
            <input
              type="radio"
              id="good"
              class="form-check-input"
              name="houseCondition"
              value="good"
              onChange={(e) => handleFormChange(e)}
            />
            <label htmlFor="good">Good</label>
          </div>
        </div>

        <button
          disabled={form.houseCondition === "" ? true : false}
          style={{ width: "10rem", marginBottom: '1rem' }}
          class="btn btn-success btn-lg"
          onClick={() => handleNextButton("listedWithAgent")}
        >
          Next
        </button>
      </Form>
    </div>
  );
};

export default HouseCondition;
