import React from "react";

import { Form, Stack } from "react-bootstrap";

const EditAddress = ({
  parsedAddress,
  handleFixAddressChange,
  setCurrentPage,
  handleEditAddressSubmit,
}) => {
  return (
    <div className="flex-column d-flex justify-content-center align-items-center vh-100 gap-3 w-100">
      <Form
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          alignItems: "center",
          justifyContent: 'center',
          width: '350px'
      
        }}
        className="text-success"
      >
        <Form.Label>Update address</Form.Label>

        <Stack className="align-items-center justify-content-center gap-4">
          <Form.Control
            name="street"
            onChange={handleFixAddressChange}
            value={parsedAddress.street}
            className="form-control-lg"
            // style={{ maxWidth: "400px", width: "100%" }}
            placeholder="Street"
          ></Form.Control>

          <Form.Control
            name="city"
            onChange={handleFixAddressChange}
            value={parsedAddress.city}
            className="form-control-lg"
            // style={{ maxWidth: "400px", width: "100%" }}
            placeholder="City"
          ></Form.Control>

          <Form.Control
            name="state"
            onChange={handleFixAddressChange}
            value={parsedAddress.state}
            className="form-control-lg"
            // style={{ maxWidth: "400px", width: "100%" }}
            placeholder="State"
          ></Form.Control>

          <Form.Control
            name="zip"
            onChange={handleFixAddressChange}
            value={parsedAddress.zip}
            className="form-control-lg"
            // style={{ maxWidth: "400px", width: "100%" }}
            placeholder="Zip"
          ></Form.Control>
        </Stack>

        <button
          onClick={handleEditAddressSubmit}
          style={{ width: "10rem" }}
          class="btn btn-success btn-lg"
        >
          Update
        </button>
      </Form>
    </div>
  );
};

export default EditAddress;
