import { Form } from "react-bootstrap";

const WhySell = ({
  handleFormChange,
  form,
  handleFormPageChange,
  handleNextButton,
  handleBackButton,
}) => {
  return (
    <div>
      <Form
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          alignItems: "center",
        }}
        className="text-success"
      >
        <Form.Label style={{ textAlign: "center" }}>What is your reason for selling? *</Form.Label>

        <div style={{ display: "flex", flexDirection: "column", gap: "3rem" }}>
          <div class="form-check">
            <input
              checked={form.whySell === "inherited"}
              type="radio"
              id="inherited"
              class="form-check-input"
              name="whySell"
              value="inherited"
              onChange={(e) => handleFormChange(e)}
            />
            <label htmlFor="inherited" class="form-check-label">
              Inherited property
            </label>
          </div>

          <div class="form-check">
            <input
              checked={form.whySell === "downsizing"}
              type="radio"
              id="downsizing"
              class="form-check-input"
              name="whySell"
              value="downsizing"
              onChange={(e) => handleFormChange(e)}
            />
            <label htmlFor="downsizing" class="form-check-label">
              Downsizing
            </label>
          </div>

          <div class="form-check">
            <input
              checked={form.whySell === "tiredOfLandlord"}
              type="radio"
              id="tiredOfLandlord"
              class="form-check-input"
              name="whySell"
              value="tiredOfLandlord"
              onChange={(e) => handleFormChange(e)}
            />
            <label htmlFor="tiredOfLandlord" class="form-check-label">
              Tired of being a landlord
            </label>
          </div>

          <div class="form-check">
            <input
              checked={form.whySell === "movingCloserToFam"}
              type="radio"
              id="movingCloserToFam"
              class="form-check-input"
              name="whySell"
              value="movingCloserToFam"
              onChange={(e) => handleFormChange(e)}
            />
            <label htmlFor="movingCloserToFam" class="form-check-label">
              Moving closer to family
            </label>
          </div>

          <div class="form-check">
            <input
              checked={form.whySell === "retirement"}
              type="radio"
              id="retirement"
              class="form-check-input"
              name="whySell"
              value="retirement"
              onChange={(e) => handleFormChange(e)}
            />
            <label htmlFor="retirement" class="form-check-label">
              Retirement elsewhere
            </label>
          </div>

          <div class="form-check">
            <input
              checked={form.whySell === "other"}
              type="radio"
              id="other"
              class="form-check-input"
              name="whySell"
              value="other"
              onChange={(e) => handleFormChange(e)}
            />
            <label htmlFor="other" class="form-check-label">
              Other
            </label>
          </div>
        </div>

        <button
          disabled={form.whySell === "" ? true : false}
          style={{ width: "10rem" }}
          class="btn btn-success btn-lg"
          onClick={() => handleNextButton("repairs")}
        >
          Next
        </button>
        <button
          onClick={() => handleBackButton("howLongOwned")}
          style={{ width: "10rem", marginBottom: '1rem' }}
          class="btn btn-dark btn-lg"
        >
          Back
        </button>
      </Form>
    </div>
  );
};

export default WhySell;
